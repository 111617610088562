import { Flex, useTheme } from '@aws-amplify/ui-react';
import styled, { css } from 'styled-components';
import { media } from '@theme/mediaQueries';
import { useEffect, useState, useRef } from 'react';
import LinksSlice from '@slices/Links';

import AnimateHeight from 'react-animate-height';
import { PrismicLink } from '@prismicio/react';

import ChevronDown from '@public/images/chevron-down.svg';
import { useRouter } from 'next/router';
import { linkResolver } from '../../prismicio';

type MainMenuItemProps = {
  key: string;
  menuItemId: string;
  activeMenuId: string | null;
  setActiveMenuId: any;
  item: any;
  firstItem?: string;
};

const SubMenuStyled = styled.div<any>`
  position: relative;
  transform: translate3d(0, 0, 0);

  ${(props) => media('medium')`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: -2;

    ${(props: any) =>
      props.theme?.tokens?.colors?.background?.secondary?.value &&
      css`
        background-color: ${props.theme?.tokens?.colors?.background?.secondary
          ?.value};
        color: ${props.theme?.tokens?.colors?.primary?.value};
      `}

    ${(props: any) => {
      props.$active &&
        css`
          z-index: -1;
        `;
    }}
  `}
`;

const MenuItemStyled = styled.div<{$firstItem?: string, $open?: boolean}>`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -25px;
    bottom: 0;
    right: -25px;
    transition: background-color 0.2s ease-in-out;

    ${(props) =>
      props.$firstItem &&
      css`
        top: -1.25rem;
      `}

    ${(props) =>
      props.$open &&
      css`
        background-color: ${props.theme.tokens.colors.background.quaternary
          .value};
      `}
  }

  ${media('medium')`
    position: static;

    &:before {
      display: none;
    }
  `}
`;

const MenuActionStyled = styled(PrismicLink)<any>`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  padding: 0.625rem 0;
  background: transparent;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  font-size: 1.125rem;
  position: relative;
  text-transform: uppercase;
  font-family: var(--fonts-default-title);
  margin-top: 0.375rem;
  color: ${(props) => props?.theme.tokens.colors.primary.value};

  svg {
    flex-shrink: 0;
    flex-grow: 0;
    width: 1.5rem;
    fill: currentColor;
    transition: transform 0.4s ease-in-out;
    margin-bottom: 0.375rem;

    ${(props) =>
      props.open &&
      css`
        transform: rotate(-180deg);
      `}
  }

  ${media('medium')`
    color: white;
    padding: 0;
    font-size: 0.875rem;
    position: static;

    &:hover {
      color: ${(props: any) => props.theme.tokens.colors.buttonPrimary.value};
    }

    ${(props: any) =>
      props.open &&
      css`
        color: ${props.theme.tokens.colors.buttonPrimary.value};
      `}

    svg {
      width: 1rem;
    }
  `}
`;

export default function MainMenuItem({
  menuItemId,
  activeMenuId,
  setActiveMenuId,
  item,
  firstItem,
}: MainMenuItemProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const first = firstItem === 'true';
  const clickRef = useRef<HTMLDivElement>(null);

  const handleToggleClick = () => {
    if (menuItemId === activeMenuId) {
      setActiveMenuId(null);
    } else {
      setActiveMenuId(menuItemId);
    }
  };

  const handleRouteChange = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(activeMenuId === menuItemId);
  }, [activeMenuId, menuItemId]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
  }, [router.events]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent): void => {
      if (clickRef.current && !clickRef.current.contains(e.target as Node)) {
        // if the element doesn't match the ref then close the menu
        setOpen(false);

        if (menuItemId === activeMenuId) {
          /* if the menu is closed via a click outside
           * we need to reset the menu id if the user clicks
           * the same menu item
           */
          setActiveMenuId(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  function handleScroll() {
    setOpen(false);
    setActiveMenuId(null);
  }

  function handleResize() {
    setOpen(false);
    setActiveMenuId(null);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <MenuItemStyled $firstItem={first.toString()} $open={open} theme={theme}>
      <Flex direction={{ base: 'column', medium: 'row' }} gap="0">
        {item.children && item.children.length > 0 ? (
          <div ref={clickRef}>
            <MenuActionStyled
              as="button"
              aria-expanded={open}
              aria-controls={menuItemId}
              onClick={handleToggleClick}
              open={open}
              theme={theme}
            >
              {item && item.parent_name}
              <ChevronDown />
            </MenuActionStyled>
            <SubMenuStyled
              theme={theme}
              $active={activeMenuId === menuItemId}
            >
              <AnimateHeight
                id={menuItemId}
                height={open ? 'auto' : 0}
                duration={400}
                easing="ease-in-out"
              >
                <LinksSlice
                  items={item.children}
                  bottom_text={item.bottom_text}
                  link={item.parent_link}
                />
              </AnimateHeight>
            </SubMenuStyled>
          </div>
        ) : (
          <MenuActionStyled
            field={item.parent_link}
            theme={theme}
            linkResolver={linkResolver}
          >
            {item.parent_name}
          </MenuActionStyled>
        )}
      </Flex>
    </MenuItemStyled>
  );
}
