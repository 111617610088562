import { PrismicLink, PrismicText } from '@prismicio/react';
import { linkResolver } from '../../prismicio';

export function TermsAndConditionsNavigation({ termlinks }: any) {
  return (
    <nav>
      <ul className="wwi-ul">
        {termlinks?.data?.slices.map((slice: any) => {
          return (
            <li className="wwi-copyright__list-item" key={slice.id}>
              <PrismicLink
                className="wwi-copyright__link text-link"
                field={slice.primary.link}
                linkResolver={linkResolver}
              >
                <PrismicText field={slice.primary.name} />
              </PrismicLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
