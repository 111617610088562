import Header from '../components/header';
import FooterSection from './navigation/footer/footerSection';

export default function Layout({ children, footer, termlinks, page, menu }: any) {
  return (
    <>
      <Header page={page} menu={menu} />
      <main>{children}</main>
      <FooterSection footer={footer} termlinks={termlinks} />
    </>
  );
}
