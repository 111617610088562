import { Grid, useTheme, View, Flex } from '@aws-amplify/ui-react';
import NextLink from 'next/link';
import Container from '@components/container';
import styled from 'styled-components';
import Image from 'next/image';
import { media } from '@theme/mediaQueries';
import { FooterNavigation } from '.';
import PrimaryButton from '@components/buttons/primary';
import { TermsAndConditionsNavigation } from '../TermsAndConditions';
import { PrismicText } from '@prismicio/react';

const FooterCopyrightStyles = styled.div`
  background-color: ${(props) => props?.theme.tokens.colors.secondary.value};
  padding: 20px 0;
  color: ${(props) => props?.theme.tokens.colors.white.value};

  .wwi-footer-logo {
    font-family: var(--fonts-default-title);
    font-size: 1.375rem;
  }

  .wwi-copyright {
    &__list-item {
      display: inline-block;
    }

    &__link {
      color: ${(props) => props?.theme.tokens.colors.white.value};
      margin-right: 12px;
      font-size: 12px;
      display: inline-block;
    }

    &__year {
      font-size: 12px;
    }
  }

  .wwi-footer-logo {
    display: block;
    max-width: 103px;
  }

  ${media('medium')`
    padding: 30px 0;

    .wwi-footer-logo {
      max-width: 211px;
    }
  `};
`;

export default function FooterSection(props: any) {
  const theme = useTheme(),
    startYear = 2023,
    currentYear = new Date().getFullYear();

  return (
    <View as="footer">
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="nowrap"
        gap="0"
      >
        {props?.footer?.data?.cta_link && (
          <PrimaryButton fullWidth="true" field={props?.footer.data.cta_link}>
            <PrismicText field={props?.footer.data.cta} />
          </PrimaryButton>
        )}

        <FooterNavigation footer={props?.footer} />

        <FooterCopyrightStyles theme={theme} className="wwi-copyright">
          <Container>
            <Grid
              templateColumns={{
                base: '1fr',
                medium: '1fr 1fr',
              }}
            >
              <View as="div">
                <NextLink href="/" locale={props?.footer.lang} className="wwi-copyright__link">
                  <span className="wwi-footer-logo">
                    <Image
                      src="/images/logo-tagline.svg"
                      alt="Save Our Wild Isles"
                      width="211"
                      height="42"
                    />
                  </span>
                </NextLink>
              </View>
              <Flex
                direction="row"
                alignItems="center"
                justifyContent={{ base: 'flex-start', medium: 'flex-end' }}
                gap="0"
              >
                <TermsAndConditionsNavigation termlinks={props?.termlinks} />

                <span className="wwi-copyright__year">
                  Copyright{' '}
                  {currentYear !== startYear ? (
                    <span>
                      {startYear} - {currentYear}
                    </span>
                  ) : (
                    startYear
                  )}
                </span>
              </Flex>
            </Grid>
          </Container>
        </FooterCopyrightStyles>
      </Flex>
    </View>
  );
}
