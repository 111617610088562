import { PrismicLink, PrismicText } from '@prismicio/react';
import { Flex, Grid, useTheme, View } from '@aws-amplify/ui-react';
import styled from 'styled-components';
import { media } from '@theme/mediaQueries';
import Container from '@components/container';
import LocalLink from '@components/global/localLink';
import { linkResolver } from '../../../prismicio';

const FooterInnerStyles = styled.div`
  background-color: ${(props: any) => props?.theme.tokens.colors.primary.value};
  padding: 43px 0;
  color: ${(props: any) => props?.theme.tokens.colors.white.value};

  .wwi-footer-menu {
    &__title {
      color: ${(props: any) => props?.theme.tokens.colors.white.value};
      font-size: var(--font-size-label--mobile);
      margin-bottom: 21px;
      display: inline-block;
      font-family: var(--fonts-default-title);
    }

    &__link {
      color: ${(props: any) => props?.theme.tokens.colors.white.value};
      display: inline-block;
      font-size: var(--font-size-label--mobile);
      margin-bottom: 13px;
      text-transform: capitalize;
    }

    &__list-item {
      flex: 0 1 40%;
    }
  }

  ${media('medium')`
    padding: 70px 0;

    .wwi-footer-menu {

      &__title {
        font-size: var(--font-size-label);
      }

      &__list-item {
        flex: 1 1 0;
      }
    }

    .wwi-footer-title {
      &__link {
        margin-bottom: 20px;
      }
    }
  `};
`;

export function FooterNavigation({ footer }: any) {
  const theme = useTheme();

  return (
    <FooterInnerStyles theme={theme}>
      <Container>
        <View as="nav">
          <div className="wwi-ul">
            <Flex
              as="ul"
              direction="row"
              wrap="wrap"
              justifyContent="space-between"
              rowGap="2.5rem"
              columnGap={{ base: '2.25rem', large: '4.25rem', xl: '5.25rem' }}
              className="wwi-footer-menu"
            >
              {footer?.data?.slices.map((slice: any) => {
                return (
                  <li key={slice.id} className="wwi-footer-menu__list-item">
                    <LocalLink
                      link={slice.primary.link}
                      className="wwi-footer-menu__title text-link"
                    >
                      <PrismicText field={slice.primary.name} />
                    </LocalLink>
                    {slice.items.length > 0 && (
                      <div className="wwi-ul">
                        <Grid
                          as="ul"
                          templateColumns={{ base: '1fr', medium: '1fr 1fr' }}
                          columnGap="2rem"
                          className="wwi-footer-menu"
                        >
                          {slice.items.map((item: any) => {
                            if (
                              item.child_link.isBroken ||
                              item.child_link.link_type === 'Any'
                            ) {
                              return;
                            }
                            return (
                              <li key={JSON.stringify(item)}>
                                <PrismicLink
                                  className="wwi-footer-menu__link text-link"
                                  field={item.child_link}
                                  linkResolver={linkResolver}
                                >
                                  <PrismicText field={item.child_name} />
                                </PrismicLink>
                              </li>
                            );
                          })}
                        </Grid>
                      </div>
                    )}
                  </li>
                );
              })}
            </Flex>
          </div>
        </View>
      </Container>
    </FooterInnerStyles>
  );
}
