import { Divider, Flex, Text, View, Link } from '@aws-amplify/ui-react';
import NextLink from 'next/link';
import { linkResolver } from '../../prismicio';
import Container from '../container';
import styled, { css } from 'styled-components';
import { useTheme } from '@aws-amplify/ui-react';
import Image from 'next/image';
import { media } from '@theme/mediaQueries';
import { useState, useRef, useEffect, Fragment } from 'react';
import MainMenuItem from '../main-menu/main-menu-item';
import PrimaryButton from '@components/buttons/primary';
import HtmlText from '@components/text/htmlText';
import MainMenuGroup from '@components/main-menu/main-menu-group';
import HeaderToggle from './toggle';
import { useRouter } from 'next/router';

type HeaderProps = {
  page: any;
  menu: any;
};

type Lang = {
  active: boolean;
  url: string;
  lang: string;
};

interface MainMenuProps {
  open: any;
  $transitioning: any;
  ref: any;
}

const LogoLinkStyled = styled(NextLink)`
  cursor: pointer;
`;

const MobileMenuStyled = styled.div`
  padding: 2.5rem 0;

  ${(props) =>
    props.theme?.tokens?.colors?.primary?.value &&
    css`
      background-color: ${props.theme?.tokens?.colors?.primary?.value};
      color: white;
    `}

  ${media('medium')`
    display: none;
  `};
`;

const MainMenuStyled = styled.div<MainMenuProps>`
  position: absolute;
  top: 100%;
  left: 100%;
  width: 100%;
  max-height: calc(100vh - 128px);
  transform: none;
  transition: transform 0.4s ease-in-out;
  display: none;
  overflow: auto;
  overscroll-behavior: none;

  ${(props) =>
    props.open &&
    css`
      transform: translate3d(-100%, 0, 0);
    `}

  ${(props) =>
    (props.open || props.$transitioning) &&
    css`
      display: block;
    `};

  ${(props) =>
    props.theme?.tokens?.colors?.background?.secondary?.value &&
    css`
      background-color: ${props.theme?.tokens?.colors?.background?.secondary
        ?.value};
    `}

  ${media('medium')`
    position: static;
    max-height: none;
    transform: none;
    display: block;

    ${(props: any) =>
      props.theme?.tokens?.colors?.primary?.value &&
      css`
        background-color: ${props.theme?.tokens?.colors?.primary?.value};
        color: white;
      `}
  `}
`;

const HeaderStyled = styled.div`
  position: relative;
  z-index: 3;
  overflow-x: clip;
`;

const UtilityHeaderStyled = styled.div`
  position: relative;
  z-index: 4;

  ${(props) =>
    props.theme?.tokens?.colors?.secondary?.value &&
    css`
      background-color: ${props.theme?.tokens?.colors?.secondary?.value};
    `}
`;

const HeaderColumnStyled = styled(Flex)`
  flex-grow: 1;
  flex-basis: 0;

  .wwi-btn {
    color: ${(props) => props?.theme?.tokens?.colors?.primary.value} !important;
  }
`;

const FlexLinksStyled = styled(Flex)`
  font-size: 0.875rem;
  font-family: var(--fonts-default-title);
  padding: 0.625rem 0;
`;
export default function Header({ page, menu }: HeaderProps) {
  const theme = useTheme();
  const mainMenuRef = useRef();
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [mainMenuTransitioning, setMainMenuTransitioning] = useState(false);
  const [activeMenuId, setActiveMenuId] = useState(null);
  const router = useRouter();
  const [translations, setTranslations] = useState<Array<Lang>>();

  const handleMenuToggle = () => {
    setMainMenuTransitioning(true);
    setTimeout(() => {
      setMainMenuOpen(!mainMenuOpen);
    }, 0);
  };

  const handleMainMenuTransitionEnd = () => {
    setMainMenuTransitioning(false);
  };

  const handleRouteChange = () => {
    setMainMenuTransitioning(true);
    setTimeout(() => {
      setMainMenuOpen(false);
    }, 0);
  };

  useEffect(() => {
    if (!mainMenuOpen) {
      setActiveMenuId(null);
    }
  }, [mainMenuOpen, setActiveMenuId]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
  }, [router.events]);

  useEffect(() => {
    if (page) {
      const translationObject: Array<Lang> = [
        {
          url: linkResolver(page),
          lang: page.lang,
          active: true,
        },
      ];
      if (page.alternate_languages.length > 0) {
        page.alternate_languages.forEach((lang: any) => {
          translationObject.push({
            url: linkResolver(lang),
            lang: lang.lang,
            active: false,
          });
        });
      } else {
        // Set homepage as default if no translation for page.
        translationObject.push({
          url: '/',
          lang: page.lang == 'en-gb' ? 'cy-gb' : 'en-gb',
          active: false,
        });
      }
      // Sort array so English is first.
      translationObject.sort((el: Lang) => (el.lang === 'en-gb' ? -1 : 1));
      setTranslations(translationObject);
    }
  }, [page]);

  return (
    <>
      <UtilityHeaderStyled theme={theme}>
        <Container>
          <FlexLinksStyled justifyContent="end">
            {translations?.map((lang: Lang, i: number) => (
              <Fragment key={i}>
                <Link
                  aria-disabled={true}
                  key={lang.lang}
                  href={lang.url}
                >
                  <Text
                    color={
                      !lang.active ? 'white' : theme.tokens.colors.borderMain
                    }
                  >
                    {lang.lang === 'en-gb' ? 'English' : 'Cymraeg'}
                  </Text>
                </Link>
                {i + 1 !== translations.length && (
                  <Divider size="small" orientation="vertical" />
                )}
              </Fragment>
            ))}
          </FlexLinksStyled>
        </Container>
      </UtilityHeaderStyled>
      <HeaderStyled>
        <MobileMenuStyled theme={theme}>
          <Container>
            <Flex alignItems="center">
              <HeaderColumnStyled>
                <HeaderToggle
                  active={mainMenuOpen}
                  onClick={handleMenuToggle}
                  aria-expanded={mainMenuOpen}
                  aria-controls="main-menu"
                  aria-label="Toggle Main menu"
                />
              </HeaderColumnStyled>
              <HeaderColumnStyled justifyContent="center">
                <LogoLinkStyled href="/" locale={page?.lang || 'en-gb'}>
                  <Image
                    src="/images/logo-tagline.svg"
                    alt="Save Our Wild Isles"
                    width="211"
                    height="42"
                  />
                </LogoLinkStyled>
              </HeaderColumnStyled>

              <HeaderColumnStyled>
                {/** Empty div for flex grow spacing */}
              </HeaderColumnStyled>
            </Flex>
          </Container>
        </MobileMenuStyled>
        <MainMenuStyled
          theme={theme}
          open={mainMenuOpen}
          $transitioning={mainMenuTransitioning}
          onTransitionEnd={handleMainMenuTransitionEnd}
          ref={mainMenuRef}
          id="main-menu"
        >
          <View padding={{ base: '1.25rem 0 2.5rem', medium: '2rem 0' }}>
            <Container>
              <Flex
                alignItems={{ base: 'stretch', medium: 'center' }}
                direction={{ base: 'column', medium: 'row' }}
                gap={{ base: 0, medium: '1rem' }}
              >
                <HeaderColumnStyled alignItems="stretch">
                  <MainMenuGroup gap={{ base: 0, medium: '1.5rem' }}>
                    {menu?.items &&
                      menu.items
                        .slice(0, 2)
                        .map((item: any, index: number) => (
                          <MainMenuItem
                            menuItemId={item.id}
                            activeMenuId={activeMenuId}
                            setActiveMenuId={setActiveMenuId}
                            key={item.id}
                            item={item}
                            firstItem={(index === 0).toString()}
                          />
                        ))}
                  </MainMenuGroup>
                </HeaderColumnStyled>

                <HeaderColumnStyled
                  justifyContent="center"
                  display={{ base: 'none', medium: 'flex' }}
                >
                  <LogoLinkStyled href="/" locale={page?.lang || 'en-gb'}>
                    <Image
                      src="/images/logo-tagline.svg"
                      alt="Save Our Wild Isles"
                      width="211"
                      height="42"
                    />
                  </LogoLinkStyled>
                </HeaderColumnStyled>

                <HeaderColumnStyled justifyContent="end" alignItems="stretch" theme={theme}>
                  <MainMenuGroup gap={{ base: 0, medium: '1.5rem' }}>
                    {menu?.items &&
                      menu.items
                        .slice(2)
                        .map((item: any) => (
                          <MainMenuItem
                            menuItemId={item.id}
                            activeMenuId={activeMenuId}
                            setActiveMenuId={setActiveMenuId}
                            key={item.id}
                            item={item}
                          />
                        ))}
                    {menu?.cta && (
                      <View marginTop={{ base: '1.25rem', medium: 0 }}>
                        <PrimaryButton field={menu.cta.cta_link} menuCta={true}>
                          <HtmlText
                            field={menu.cta.cta}
                            customClass="mt-0 mb-0 wwi-btn"
                          />
                        </PrimaryButton>
                      </View>
                    )}
                  </MainMenuGroup>
                </HeaderColumnStyled>
              </Flex>
            </Container>
          </View>
        </MainMenuStyled>
      </HeaderStyled>
    </>
  );
}
