import { ReactNode } from 'react';
import { Flex } from '@aws-amplify/ui-react';

type MainMenuGroupProps = {
  children: ReactNode;
  gap: any;
};

export default function MainMenuGroup({
  children,
  ...props
}: MainMenuGroupProps) {
  return (
    <Flex
      direction={{ base: 'column', medium: 'row' }}
      alignItems={{ base: 'stretch', medium: 'center ' }}
      grow={{ base: '1', medium: '0' }}
      {...props}
    >
      {children}
    </Flex>
  );
}
