import styled, { css } from 'styled-components';
import { ReactNode } from 'react';

type HeaderToggleProps = {
  children?: ReactNode;
  active: boolean;
  onClick: () => void;
};

interface StyledButtonProps {
  $active?: boolean;
}

const HeaderToggleStyled = styled.button<StyledButtonProps>`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0;
  border-width: 0;
  background-color: transparent;
  padding: 0;
  position: relative;

  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 1rem;
    display: inline-block;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: transform 0.2s ease-in-out, margin 0.2s ease-in-out;
  }

  &:before {
    transform: translate(-50%, -100%);

    ${(props) =>
      props.$active &&
      css`
        transform: translate(-50%, -50%) rotate(45deg);
      `}
  }

  &:after {
    transform: translate(-50%, 100%);

    ${(props) =>
      props.$active &&
      css`
        margin-top: 0;
        transform: translate(-50%, -50%) rotate(-45deg);
      `}
  }

  &:focus-visible {
    outline: white 1px auto;
  }
`;

export default function HeaderToggle({
  children,
  ...props
}: HeaderToggleProps) {
  return <HeaderToggleStyled $active={props.active}>{children}</HeaderToggleStyled>;
}
